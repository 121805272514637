*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: inherit;
  font-size: inherit;
  scroll-behavior: smooth;
}

body{
  background: #1c1c1c;
}

:root{
  --main: #1c1c1c;
  --secondary: rgb(20, 20, 20, .98);

}

.container{
  width: 100%;
  max-height: 100%;
}

.app {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  margin: 0 auto;
  color: #ffffff;
}
@media (max-width: 600px){
  .app{
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    justify-content: center;
  }
}

/*-----------------------------------------HEADER-------------------------------------------*/

nav{
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: fit-content;
}

ul{
  list-style: none;
}

.site-header{
  z-index: 0;
  margin-top: 20px
}

.mobile-menu-button{
  display: none;
}

.header-container{
  display: flex;
  flex-direction: row;
  margin: 0 20px;
  padding-bottom: 80px;
  line-height: 2;
}

.header-title{
  font-size: 40px;
  font-weight: 500;
}

.header-subtitle{
  font-size: 20px;
  font-weight: 200;
  font-style: italic;
}

.header-logo{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 10px;
}

.site-logo{
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 300px;
}

.header-contact{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 2;
  font-size: 20px;
  font-weight: 200;
}

.header-navigation{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  background-color: var(--secondary);
}

.navbar-options{
  display: flex;
  flex-direction: row;
  margin: 0 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.navbar-options .link{
  text-decoration: none;
  font-size: 13px;
  font-weight: 100;
  margin-right: 12px;
  padding-inline: 10px;
}
.navbar-options .link:hover{
  color: rgb(0, 69, 133);
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 1);
  transition: color 0.2s ease-in-out, text-shadow 0.1s ease-in-out;
}

.social-buttons{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  transform: scale(85%);
  justify-content: space-between;
}


@media (max-width: 600px){

  .site-header{
    margin-top: 4px
  }

  .mobile-menu-button{
    display: block;
    text-align: end;
    font-size: 38px;
    padding: 2px;
  }

  .mobile-menu {
    display: block;
    background-color: var(--secondary);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: ease-in-out 1.5s;
  }

  .mobile-menu .navbar-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .mobile-menu .link {
    text-decoration: none;
    font-size: 18px;
    color: #fff;
    line-height: 3;
    font-size: 24px;
    margin-inline: 0;
    padding-inline: 10px;
  }


  .header-container{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding-bottom: 20px;
  }

  .title-box{
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }
  .header-text{
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .header-contact{
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  
  .header-navigation{
    display: none;
  }

}




/*-----------------------------------FOOTER--------------------------------------------------*/

.site-footer{
  display: flex;
  flex-direction: column;
  position: relative;
  width: 75%;
  padding: 20px auto;
  z-index: 0;
  background-color: var(--main);
  padding-bottom: 10px;
  padding-top: 40px;
  margin: 20px auto;
}
.site-footer h1{
  font-size: 28px;
  font-weight: 100;
  margin-left: 10%;
}
.footer-top{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 40px;
}

.footer-navigation{
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 0 auto;
}

.site-footer .link{
  margin: 0 20px;
  text-decoration: none;
  font-size: 16px;
}
.site-footer .link:hover{
  color: rgb(0, 69, 133);
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 1);
}

.footer-column{
  margin-top: 10px;
}


.site-footer .social-buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  transform: scale(.75);
}

.copyright{
  display: flex;
  justify-content: center;
  font-size: 12px;
  margin: 0 auto;
}

@media (max-width: 600px){
  .footer-navigation .navbar-options{
    display: flex;
    flex-direction: column;
  }

  .site-footer{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 90%;
    padding: 20px auto;
    z-index: 0;
    background-color: var(--main);
    padding-bottom: 10px;
    padding-top: 40px;
    margin: 20px auto;
  }
  .site-footer h1{
    font-size: 22px;
    font-weight: 100;
    margin: 0 auto;
    text-align: center;
  }

  .footer-top{
    flex-direction: column;
    align-items: center;
  }
  
  .footer-column{
    margin-top: 10px;
    padding: 10px;
  }

  .site-footer .social-buttons{
    display: flex;
    flex-direction: row;
    transform: scale(.75);
  }

}



/*-----------------------------------GENERAL PAGE SETTING--------------------------------------------------*/
.page-container{
  display: flex;
  flex-direction: row;
  z-index: 0;
}
@media (max-width: 600px){
  .page-container{
    flex-direction: column;
  }
}


.page-title{
  display: flex;
  flex-direction: column;
  font-size: 60px;
  line-height: 2;
  margin: 10px auto;
}

.subtitle{
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  margin: 10px auto;
}

.page-heading{
  width: 50%;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 40px auto;
}

.title{
  justify-content: center;
  text-align: center;
  font-size: 60px;
  font-weight: 500;
  margin: 20px;
}

.title span {
  font-weight: 100;
}

/*-----------------------------------HOME PAGE-------------------------------------------------*/


.home-img{
  background-image: url('./content/images/home-background.png');
  background-size: cover;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.home-container{
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 40px 40px;
  text-align: center;
  z-index: 1;
  background-color: var(--main);
}

.home-header{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}

.home-title{
  font-size: 80px;
  font-weight: 500;
  line-height: 2;
  margin: 10px auto;
}

.home-subtitle{
  font-size: 28px;
  font-weight: 400;
  font-style: italic;
  margin: 10px auto;
}


.home-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
  font-size: 18px;
  font-weight: 100;
  line-height: 2;
  margin: 0 auto;
}


@media (max-width: 600px){
  
  .home-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: start;
    margin: 0;
    margin-top: 300px;
    padding: 8px;
  }
  .home-img{
    background-size: 200%;
    background-position:top;
    background-repeat: no-repeat;
    background-attachment:local;
  }

  .home-header{
    text-align: start;
    align-items: start;
    line-height: 2;
    margin: 0;
  }

  .home-title{
    font-size: 54px;
    font-weight: 400;
    line-height: 1;
    margin: 0;
  }

  .home-subtitle{
    font-size: 28px;
    font-weight: 400;
    font-style: italic;
    margin: 0;
  }

  .home-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: start;
    width: 100%;
    font-size: 18px;
    font-weight: 100;
    line-height: 1.25;
    margin: 0 auto;
  }
}



/*-----------------------------------UPCOMING PAGE-------------------------------------------------*/


.upcoming-img{
  background-image: url('./content/images/upcoming-page.png');
  background-size:cover;
  background-repeat: no-repeat;
  background-attachment: absolute;
  width: 100%;
  height: 100%;
  margin: 40px auto;
}

.upcoming-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  padding-block: 11em;
}

.text-overlay{
  position: absolute;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 70px;
  width: 40%;
}

.upcoming-container{
  width: 80%;
  margin: 0 auto;
}
  .upcoming-container h1{
    text-align: center;
    font-size: 36px;
    font-weight: 200;
    padding-bottom: 20px;
  }

.events-gallery{
  display: flex;
  flex-wrap: wrap;
}

.event-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 20px;
  padding: 2px;
  border: 1px solid #141414;
  border-radius: 5px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, .5);
}

.event-card {
  overflow: hidden;
  position: relative;
  height: 20em;
}

.event-card img {
  width: 100%;
  height: auto;
  max-height: 55%;
  object-fit: fit;
}
  .event-card:hover img{
    transition: ease-in-out 1.5s;
    display: none;
  }

.event-card h2 {
  font-size: 32px;
  padding: 10px;
  margin: 0;
}

.event-details {
  position:absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--main);
  padding: 10px;
  box-sizing: border-box;
  max-height: 0;
  overflow-y: auto;
  transition: max-height 0.5s ease-in-out;
  visibility: hidden;
}

.event-card:hover .event-details {
  visibility: visible;
  max-height: 100%; /* Adjust as needed */
  transition: ease-in-out .5s;
}

.event-details::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.event-details::-webkit-scrollbar-thumb {
  background-color: #fff; /* Color of the scrollbar */
  border-radius: 5px; /* Rounded corners of the scrollbar */
}

.event-details::-webkit-scrollbar-thumb:hover {
  background-color: #ccc; /* Change color on hover */
}

.event-button{
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 30%;
  padding: 4px;
  text-align: center;
  margin: 2px auto;
    text-decoration: none;
}
  .event-button:hover{
    color: rgb(0, 69, 133);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 1);
  }

@media (max-width: 600px){
  .upcoming-img{
    background-size:contain;
    background-attachment: absolute;
    width: 100%;
    height: 100%;
    margin: 40px auto;
  }

  .upcoming-header{
    flex-direction: column;
    padding-bottom: 40px;
  }
  
  .text-overlay{
    position: absolute;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 40px;
    width: 100%;
  }

  .upcoming-container h1{
    text-align: center;
    font-size: 28px;
    font-weight: 200;
    padding-bottom: 20px;
  }

  .event-container {
    width: 300px;
    margin: 10px auto;
    padding: 2px;
  }
    .event-card h2{
      font-size: 24px;
    }

  .event-card {
    height: 16em;
  }
}


/*-----------------------------------EVENT PAGE-------------------------------------------------*/


.event-page{
  display: flex;
  flex-direction: column;
  max-width: 50%;
  margin: 60px auto;
  justify-content: center;
  text-align: center;
}
  .event-page h1{
    font-size: 60px;
    font-weight: 400;
    line-height: 1.5;
    padding: 10px;
  }
  .event-page h2{
    font-size: 18px;
    font-weight: 100;
    line-height: 2;
  }
  .event-page h3{
    font-size: 20px;
    font-weight: 500;
    line-height: 2;
  }
  .event-page p{
    font-size: 18px;
    line-height: 1.5;
  }
  .event-page iframe{
      width: 100%;
      height: 400px;
      margin: 2px;
    }

.event-logo{
  max-width: 100%;
  min-width: 50%;
  margin: 40px auto;
}

.event-top{
  margin: 20px auto;
}

.event-bottom{
  justify-content: start;
  text-align: start;
  padding: 20px;
  margin: 20px auto;
}

  
@media (max-width: 600px){
  .event-page{
    max-width: 100%;
    justify-content: center;
    text-align: center;
    margin: 10px auto;
    padding: 10px;
  }
    .event-page h1{
      font-size: 56px;
      font-weight: 300;
    }
  .event-logo{
    max-width: 90%;
    min-width: 75%;
    margin: 40px auto;
  }
}

/*-----------------------------------MEDIA PAGE-------------------------------------------------*/


.media-page{
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
}

  .media-page h1{
    margin: 1em;
    font-size: 80px;
  }


.photo-gallery {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: center;
  margin-bottom: 40px;
}
  .photo-gallery img {
    max-width: 100%;
    height: auto;
  }
  .photo-gallery img:hover {
    scale: 105%;
    box-shadow: 0 0 12px 2px rgba(55, 55, 55, .8);
    transition: 0.1s ease-in-out;
  }
  

.video-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
}

.video {
  width: 32%;
  height: 300px;
  margin-bottom: 40px;
}

  .video iframe {
    border: none;
    width: 100%;
    height: 100%;
    max-width: 560px;
    max-height: 315px;
  }

@media (max-width: 600px){
  .media-page h1{
    margin: 1em;
    font-size: 40px;
  }
  .video-gallery{
    flex-direction: column;
    width: 360px;
    width: auto;
    justify-content: center;
  }
  .video{
    width: 100%;
    margin: 20px auto;
  }
}


.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the lightbox is on top of other elements */
}

.lightbox img {
  max-width: 90%; /* Adjust as needed */
  max-height: 90vh; /* Adjust as needed */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px;
  background-color: transparent; /* Semi-transparent white background */
  color: gray;
  border: none;
  cursor: pointer;
  z-index: 1001; /* Ensure the close button is on top of the lightbox */
  font-size: 40px;
}

.arrow-button {
  position: absolute;
  padding: 8px;
  background-color: transparent; /* Semi-transparent white background */
  border: none;
  cursor: pointer;
  z-index: 1001; /* Ensure the arrow button is on top of the lightbox */
  font-size: 40px;
}

  .arrow-button.left {
    left: 80px;
  }

  .arrow-button.right {
    right: 80px;
  }


/*-----------------------------------CONTACT PAGE-------------------------------------------------*/

.contact-img{
  background-image: url('./content/images/contact-page.png');
  background-size:contain;
  background-repeat: no-repeat;
  background-attachment: absolute;
  width: 100%;
  height: 100%;
}

.contact-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  padding-block: 11em;
}

.contact-left{
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 2.5;
}
  .contact-left h1{
    font-size: 40px;
    font-weight: bold;
  }

.input-container{
  display: flex;
  flex-direction: column;
}

.input-container input, textarea{
background: transparent;
border: none;
border-bottom: 1px solid white;
outline: none; /*Remove input focus outline*/
margin-top: 4px;
padding: 8px;
margin-inline: 2px;
resize: none;
}

.input-container::placeholder{
  font-size: 20px;
}

textarea{
  height: 6em;
}

.input-row{
  display: flex;
  flex-direction: row;
}

@media (max-width: 600px){
  .contact-container{
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    padding-block: 2em;
  }

  .input-row{
    flex-direction: column;
  }
}


.contact-submit{
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: transparent;
  width: 30%;
  padding: 4px;
  text-align: center;
  margin: 10px auto;
  text-decoration: none;
}
  .contact-submit:hover{
    color: rgb(0, 69, 133);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 1);
  }


.mail-chimp{
  color: #141414;
}

/*-----------------------------------BAND BIOS PAGE-------------------------------------------------*/

.band-bio-page{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bio-heading{
  width: 50%;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 40px auto;
}

.band-bio{
  font-size: 18px;
  font-weight: 100;
  line-height: 2;
}

.member-container{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.member-preview{
  display: flex;
  flex-direction: row;
  height: fit-content;
}

.member-preview.mirror{
  transform: scaleX(-1);
}
.mirror .member-bio-container{
  transform: scaleX(-1);
}

.member-title{
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px auto;
  font-size: 40px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 1);
}

.member-img-container{
  display: flex;
  flex-direction: column;
  width: 50%;
}

.member-img{
  width: 100%;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 1);
}

.member-bio-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 2px 2px;
  padding: 0 0 0 16px;
}

.member-bio{
  width: 50%;
  text-align: center;
  font-size: 18px;
  font-weight: 100;
}

@media (max-width: 600px){

  .bio-heading{
    width: 100%;
    text-align: start;
    margin: 0 auto;
    padding: 10px;
  }

  .bio-heading h1{
    line-height: 1.5;
    font-size: 48px;
  }

  .band-bio{
    line-height: 1.5;
  }

  .member-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px;
  }

  .member-preview{
    flex-direction: column;
  }

  .member-preview.mirror{
    transform: scaleX(1);
  }
  .mirror .member-bio-container{
    transform: scaleX(1);
  }

  .member-img-container{
    width: 100%;
  }

  .member-img{
    width: 100%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    margin-top: 20px;
  }

  .member-bio-container{
    width: 100%;
    margin: 0;
    padding: 0 10px;
  }

  .member-bio{
    width: 100%;
    text-align: start;
  }
}


/*-----------------------------------SONG LIST PAGE--------------------------------------------------*/

.song-list-page{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 50%;
}

.song-list-header{
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
  .song-list-header h1{
    font-size: 80px;
    font-weight: 400;
    margin-bottom: 40px;
  }
  .song-list-header h2{
    font-size: 30px;
    font-weight: 100;
  }

.song-list{
  align-items: start;
  text-align: start;
  line-height: 1.75;
  margin: 20px 0;
}


@media (max-width: 600px){
  .song-list-page{
    width: 100%;
    padding: 10px;
  }
  .song-list{
    line-height: 1.75;
  }
  .song-list-header h1{
    font-size: 58px;
  }
  .song-list-header h2{
    font-size: 22px;
  }
}

/*-----------------------------------SOCIAL MEDIA--------------------------------------------------*/

/* Style all font awesome icons */
.social-icons {
  /* margin-bottom: 20px; */
  /* padding: 20px; */
  font-size: 28px;
  width: 26px;
  text-align: center;
  margin: 0 8px;
}

/* Add a hover effect if you want */
.social-icons:hover {
  opacity: .9;
  color:black;
}

.twitter {
  background: #55ACEE;
  color: white;
} 

.facebook {
  background: #316FF6;
  color: white;
  transform: scale(75%);
} 

.instagram{
  background: #fb3958;
  color:white;
}

.youtube{
  background: #FF0000;
  color:white;
}

  /* For social icons in social-buttons */
.social-buttons .social-icons{
  background: transparent;
  height: 75%;
  position: relative;
} 
.social-buttons .twitter:hover{
  color: #657786;
}
.social-buttons .facebook:hover{
  color: #316FF6;
}
.social-buttons .instagram:hover{
  color:#fb3958;
}
.social-buttons .youtube:hover{
  color:#FF0000;
}
